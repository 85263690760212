<template>
	<div class="_pc-pad-y-30 _pc-pad-x-310 mb-pad-x-15" style="background: #f7f7f7;">
		<div class="message-index">
			<div class="head">
				<div class="left">{{$t('系统消息')}}({{ total }})</div>
				<div class="right" @click="allMsgRead">{{ $t('全部已读') }}</div>
			</div>
			<div class="_w-max _pc-pad-top-30 _pc-pad-x-25" style="height: 680px;">
				<Table size="large" max-height="660" :border="true" :columns="columns1" :data="list"  :no-data-text="$t('暂无数据')" @on-row-click="readSingleStrip">
					<template slot-scope="{ row, index }" slot="message">
						<span style="color: #AD3F38" v-if="row.read_time === 0">●</span>
						<span v-if="lang == 'zh_cn'">{{ row.content_zh }}</span>
						<span v-if="lang == 'ru'">{{ row.content_ru }}</span>
						<span v-if="lang == 'en_us'">{{ row.content_us }}</span>
					</template>
				</Table>
			</div>
			<!-- 分页 -->
			<div class="pagination">
				<Page :total="total" show-elevator show-total @on-change="changePage" :page-size="fromPage.limit" />
			</div>
		</div>
	</div>
</template>

<script>
	import { messageList, allRead, msgRead } from '@/api/jiyun';
	import { getCookies } from "@/libs/util.js";
	export default {
		name: 'messgae',
		data() {
			return {
				metaInfo: {
					title: this.$t('消息列表'),

				},
				list: [],
				fromPage: {
					limit: 10, //每个条数
					page: 1, //页数
				},
				total: 0,
				columns1: [
					{
						title: this.$t('消息内容'),
						slot: 'message'
					},
					{
						title: this.$t('时间'),
						key: 'add_time',
					},
				],
				message: [],
				lang: 'zh_cn'
			}
		},
		metaInfo() {
			return {
				title: this.metaInfo.title
			}
		},
		created(){
			let token = getCookies('web-token');
			if(token){
				this.lang = localStorage.getItem('think-lang') || 'zh_cn';
				this.getList();
			}else{
				this.$Message.warning(this.$t('请先登录'));
				// this.$router.go(-1);
				this.$router.push('/login');
			}
		},
		methods: {
			// 消息已读
			readSingleStrip(row){
				let param = {
					message_id: row.id
				}
				msgRead(param).then((res)=>{
					this.$bus.$emit('noRead');
					this.getList();
					if(res.data.type === 1){
						this.$router.push({
							path: '/mobile_report',
							query: {
								keywords: res.data.result
							}
						})
					}else if(res.data.type === 2){
						this.$router.push({
							path: '/mobile_orderDetail',
							query: {
								id: res.data.result
							}
						})
					}
				}).catch((err)=>{
					this.$Message.error(err.msg)
				})
			},
			// 全部已读
			allMsgRead(){
				allRead().then((res)=>{
					this.$Message.success(res.msg);
					this.$bus.$emit('noRead')
					this.getList();
				}).catch((err)=>{
					this.$Message.error(err.msg)
				})
			},
			// 列表
			getList(){
				messageList(this.fromPage).then((res)=>{
					let data = res.data;
					this.list = data.list;
					this.total = data.count;
				}).catch((err)=>{
					this.$Message.error(err.msg)
				})
			},
			NumberInput: function(e) {
				if (e.keyCode == 69) {
					e.returnValue = false
					return false
				}
			},
			// 分页
			changePage(e) {
				this.fromPage.page = e;
				this.getList();
			}
		}
	}
</script>

<style>
	.ivu-table td.add_time-table-info-column {
		border-left: 1px solid #eee;
	}
	.pagination ul {
		display: flex !important;
	}
</style>
<style scoped>
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}

	input[type="number"] {
		-moz-appearance: textfield;
	}

	.message-index {
		width: 100%;
		/* height: 880px; */
		border-radius: 10px;
		/* background: #ffffff; */
		position: relative;
		padding-bottom: 115px;
	}

	.message-index .head {
		width: 100%;
		height: 110px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid #E4E4E4;
	}

	.message-index .head .left {
		font-size: 25px;
		color: #222222;
		font-weight: bold;
	}

	.message-index .head .right {
		border: 1px solid #4284DC;
		border-radius: 8px;
		padding: 5px 16px;
		color: #4284DC;
		font-size: 18px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.message-index .head .right:active {
		border: none;
		background-color: rgba(66, 132, 220, 0.4);
		color: #fff;
		/* cursor: default; */
	}

	.message-index .pagination {
		position: absolute;
		width: 100%;
		height: 110px;
		bottom: 0;
		left: 0;
		color: #333333;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding-left: 30px;
		padding-right: 30px;
		/* border-top: 1px solid #eee; */
	}

	.page-button {
		height: 100%;
		display: flex;
		align-items: center;
		margin-left: 20px;
		margin-right: 50px;
	}

	.page-button .pre {
		width: 45px;
		height: 45px;
		border: 1px solid #999;
		border-radius: 6px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.page-button .pre:hover,
	.page-button .next:hover {
		background-color: rgba(153, 153, 153, 0.3);
	}

	.page-button .pre .icon-jiantou {
		transform: rotate(180deg);
	}

	.page-button .next {
		width: 45px;
		height: 45px;
		border: 1px solid #999;
		border-radius: 6px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.page-button .page-list {
		padding-left: 10px;
		padding-right: 10px;
		display: flex;
		align-items: center;
	}

	.page-button .page-list .item-page {
		width: 45px;
		height: 45px;
		border: 1px solid rgba(153, 153, 153, 0.8);
		border-radius: 6px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 10px;
		color: rgba(153, 153, 153, 1);
	}

	.page-button .page-list .item-page-select {
		border: 1px solid #4284DC;
		color: #4284DC;
	}

	.page-button .page-list .item-page:hover {
		border: 1px solid #4284DC;
		cursor: pointer;
	}

	.page-button .page-list .item-page:active {
		background-color: rgba(66, 132, 220, 0.4);
	}

	.page-button .page-list .item-page:last-child {
		margin-right: 0px;
	}

	.page-button .to-page {
		display: flex;
		align-items: center;
	}

	.number-input {
		width: 70px;
		height: 45px;
		border: 1px solid rgba(153, 153, 153, 0.3);
		margin-left: 15px;
		margin-right: 15px;
		outline: none;
		padding: 0px 12px;
		border-radius: 8px;
	}
	/*屏幕在480px到768之间（主要是手机屏幕）*/
	@media screen and (max-width: 768px){
		.display-none {
			display: none !important;
		}
		.mb-display-block {
			display: block;
		}
		/* pad */
		.mb-pad-0 {
			padding: 0 !important;
		}
		.mb-pad-x-15 {
			padding-left: 0.9375rem !important;
			padding-right: 0.9375rem !important;
		}
		.mb-pad-x-25 {
			padding-left: 1.5625rem;
			padding-right: 1.5625rem;
		}
		.mb-pad-x-30 {
			padding-left: 1.875rem;
			padding-right: 1.875rem;
		}
		.mb-pad-y-15 {
			padding-top: 0.9375rem !important;
			padding-bottom: 0.9375rem !important;
		}
		.mb-pad-y-25 {
			padding-top: 1.5625rem;
			padding-bottom: 1.5625rem;
		}
		.mb-pad-y-30 {
			padding-top: 1.875rem;
			padding-bottom: 1.875rem;
		}
		/* mar */
		.mb-mar-0 {
			margin: 0;
		}
		.mb-mar-top-30 {
			margin-top: 1.875rem;
		}
		.mb-mar-top-50 {
			margin-top: 3.125rem;
		}
		.mb-mar-right-15 {
			margin-right: 0.9375rem;
		}
		.mb-mar-y-15 {
			margin-top: 0.9375rem;
			margin-bottom: 0.9375rem;
		}
		.mb-mar-y-25 {
			margin-top: 1.5625rem;
			margin-bottom: 1.5625rem;
		}
		/* width */
		.mb-w-max {
			width: 100%;
		}
		.mb-w-70 {
			width: 4.375rem;
		}
		.mb-w-100 {
			width: 6.25rem;
		}
		.mb-w-18 {
			width: 1.125rem;
		}
		/* height */
		.mb-h-60 {
			height: 3.75rem;
		}
		/* font */
		.mb-font-15 {
			font-size: 0.9375rem !important;
		}
		.mb-font-20 {
			font-size: 1.25rem;
		}
		.mb-font-25 {
			font-size: 1.5625rem;
		}
		.mb-font-30 {
			font-size: 1.875rem;
		}
		.mb-font-35 {
			font-size: 2.1875rem;
		}
		
		.mb-text-center {
			text-align: center;
		}
		/* flex */
		.mb-flex-1 {
			flex: 1;
		}
		.mb-flex-2 {
			flex: 2;
		}
		.mb-flex-col-center-mid {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}
		.mb-flex-row-bet-mid {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
		}
		.mb-flex-row-mid {
			display: flex;
			flex-direction: row;
			align-items: center;
		}
		.mb-flex-shrink {
			flex-shrink: 0;
		}
		.mb-border-radius-10 {
			border-radius: 0.625rem;
		}
		.mb-border-radius-15 {
			border-radius: 0.9375rem;
		}
		.mb-grid-column-max-span{
			grid-column: 1 / -1;
		}
		.mb-order-1 {order: 1;}
		.mb-order-2 {order: 2;}
		.mb-order-3 {order: 3;}
		.mb-order-4 {order: 4;}
		.mb-order-5 {order: 5;}
		.mb-order-6 {order: 6;}
		.mb-order-7 {order: 7;}
		.mb-order-8 {order: 8;}
		.mb-order-9 {order: 9;}
		.mb-order-10 {order: 10;}
		.mb-main-box {
			width: 100%;
			background: #fff;
			padding-bottom: 1.875rem;
		}
		.mb-main-box .mb-main-box-nav {
			width: 100%;
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			border-bottom: 1px solid #e2e2e2;
		}
		.mb-main-box .mb-main-box-nav .mb-li {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			padding: 0.5rem 0rem;
			font-size: 0.9375rem;
		}
		.mb-main-box .mb-main-box-nav .mb-li-select {
			color: #4284DC;
			font-weight: bold;
			position: relative;
		}
		.mb-main-box .mb-main-box-nav .mb-li-select::after {
			content: '';
			position: absolute;
			bottom: -1px;
			width: 16%;
			height: 0.125rem;
			background-color: #4284DC;
		}
		.mb-radio-list {
			width: 100%;
			display: grid;
			grid-template-columns: 1fr 1fr 1fr 1fr;
		}
		.radio_type:checked:after{
			content: '';
			width: 10px;
			height:5px;
			border: 2px solid white;
			border-top: transparent;
			border-right: transparent;
			text-align: center;
			display: block;
			position: absolute;
			top: 0.4375rem;
			left:5px;
			vertical-align: middle;
			transform: rotate(-45deg);
		}
	}
</style>